export const header = {
  currentTeam: 'Current @:featuresTerms.team',
  currentSingleGroupTeam: 'Current @:glossary.singleGroupTeam',
  createTeam: 'New @:featuresTerms.team',
  uAreAdmin: 'You are the @:featuresTerms.teamAdmin',
  teamSettings: '@:featuresTerms.team Settings',
  singleGroupTeamSettings: '@:glossary.singleGroupTeam Settings',
  teamProfile: '@:featuresTerms.team profile',
  singleGroupTeamProfile: '@:glossary.singleGroupTeam profile',
  searchPlaceholder: 'Search',
  searchThisChatPlaceholder: 'Search in this chat',

  openChat: 'Go to chat',
  hintWarning: 'Type at least {min}',
  recentQueries: 'Recent searches',
  forNav: 'to navigate',
  forSelect: 'to select',
  forCancel: 'to dismiss',
  everywhere: 'Everywhere',
  chatOnly: 'In this chat',
  inChat: 'In chat',
  disableChatOnly: 'Disable search in this chat only',
  enableChatOnly: 'Enable search in this chat only',

  myProfile: 'My profile',
  applications: 'Applications',
  loginViaQr: 'Log in via QR code',
  exit: 'Log out',

  hideChatsList: 'Hide chat list',
  showChatsList: 'Show chat list',

  notificationBar: {
    allowNotification: 'Please choose "Allow" to enable notifications',
    turnOnNotification: 'Enable notifications',
    notifyQuestion: 'Would you like to receive notifications about new messages?',
  },
}
