export const meetings = {
  hasMore: 'And {n}',
  meetingDialog: {
    title: 'Change',
    titleNew: 'New meeting',
    titleEditing: 'Edit',
    meetingTypeSubTitle: 'Members will be notified that the meeting will be with an audio call',
    meetingTypeTitle: 'Online meeting',
    cancel: 'Cancel',
    create: 'Create',
    save: 'Save',
    close: 'Close',
    description: 'Description…',
    start: 'Start',
    descriptionErrorMsg: 'Enter a description of the meeting',
    descriptionErrorMsgLength: 'Exceeded maximum amount of characters',
    end: 'End',
    hour: '{n} hours | {n} hour | {n} hours | {n} hours',
    min: '{min} min',
    error: {
      title: 'Incorrect time period',
      description: 'Make the start date of the meeting earlier than the end date of the meeting',
    },
    wishlist: {
      title: 'Collecting wishes',
      description: 'Do&nbsp;you use this feature in&nbsp;other calendars? In&nbsp;which cases do&nbsp;you need to&nbsp;change only a&nbsp;part of&nbsp;repeating meetings?',
      goTo: 'Describe in WishList',
    },
    tabs: {
      all: 'All meetings',
      cell: 'This meeting',
      next: 'This and following',
    },
    cell: {
      info: 'You can only change date and time of&nbsp;specific meeting',
    },
  },
  removeDialog: {
    title: 'Delete meeting',
    titleRepeatable: 'Delete repetitive meeting {name}?',
    descriptionRepeatable: 'All meetings will be deleted',
    description: 'Are you sure you want to delete the meeting',
    remove: 'Delete',
    cancel: 'Cancel',
    okCaption: 'Delete all',
    cancelCaption: 'Do not delete',
  },
  membersSelector: {
    placeholder1: 'Enter your name',
    placeholder2: 'Add members',
  },
  meeting: {
    actions: {
      notification: 'Notifications',
      counters: 'Unread counter',
      link: 'Copy link',
    },
    errorAccessDenied: 'The meeting is private, only its participants have access.<br />Meeting creator can add you to the meeting',
  },
  rightBar: {
    headerTitle: 'Meetings',
    actions: {
      shortView: 'compact view',
    },
    filters: {
      show: {
        next: 'Upcoming and current',
        title: 'Show',
        all: 'All',
      },
    },
    list: {
      empty: {
        title: 'You dont have any meetings yet',
        subtitle: 'Chats of your meetings will be shown here',
      },
      card: {
        actions: {
          pin: 'Pin',
          copyLink: 'Copy link',
          unpin: 'Unpin',
          edit: 'Edit',
          delete: 'Delete',
        },
      },
    },
  },
  fullMeeting: {
    organizer: 'Organizer',
    members: 'Members',
    createdTitle: 'Created {date}',
    deadlineTitle: 'Deadline {date}',
    repeatability: 'Repeats ',
  },
  card: {
    repeatabilityDescription: 'Repeats {repeatability}',
    busy: 'Slot busy',
  },
  repeatability: {
    repeat: 'Repeat',
    byWeek: 'By week',
    byDay: 'By days',
    weekly: {
      every: 'Every',
      week: 'week',
    },
    daily: {
      every: 'Every',
      day: 'Day',
    },
    tooltip: 'On this day the meeting begins, it cannot be removed',
    confirm: {
      cancel: 'Do not disable',
      ok: 'Disable',
      title: 'Disable meeting repetition {description}?',
      description: 'All future instances of meeting will disappear from calendar ' +
        'Meeting chat and past instances will remain available.',
    },
    1: 'Mo',
    2: 'Tu',
    3: 'We',
    4: 'Th',
    5: 'Fr',
    6: 'Sa',
    7: 'Su',
  },
  createBtn: {
    tooltip: 'This is another user\'s calendar. When creating a new meeting,' +
      ' this user will automatically be added to the meeting participants',
  },
}
