export const globalSearch = {
  all: 'All',
  result_main: 'Search results',
  result_messages: 'Messages search results',
  result_tasks: 'Tasks search results',
  result_contacts: 'Contacts search results',
  result_groups: 'Groups search results',
  result_files: 'Files search results',
  params: 'Search options',

  ftAll: 'All files',
  srAll: 'Any sender',
  chatAll: 'In all chats',
  inChat: 'In',

  taskType: {
    caption: 'Task type',
    any: 'Any',
    in: 'Assigned to me',
    out: 'Created by me',
    watcher: 'Follower',
  },

  taskStatus: {
    caption: 'Task status',
    any: 'Any',
    open: 'Open',
    archive: 'Done',
  },

  watchersOnly: 'Followers only',
  showArchived: 'Show deleted',
  startTyping: 'Start typing...',
}
