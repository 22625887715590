export const dashboard = {
  groupBy: 'Group by',
  sortBy: 'Sort by',
  filter: 'Filter',
  assigneeMe: 'Assigned to me',
  clear: 'Clear',
  chooseAssignee: 'Choose assignee:',
  chooseOwner: 'Choose owner:',
  chooseTag: 'Choose tag',
  viewOptions: {
    byProject: 'Project',
    byAssignee: 'Assignee',
    byOwner: 'Owner',
    byTag: 'Tag',
    byStatus: 'Status',
    byImportance: 'Importance',
    byDepartment: 'Department',
  },
  sortingOptions: {
    byActivity: 'By activity',
    byDeadline: 'By deadline',
    newFirst: 'New first',
    oldFirst: 'Old first',
    byImportance: 'Priority',
  },
  filterOptions: {
    status: '@:glossary.status',
    assignee: '@:dashboard.viewOptions.byAssignee',
    assignee_plural: 'Assignees',
    owner: '@:dashboard.viewOptions.byOwner',
    owner_plural: 'Owners',
    tag: '@:dashboard.viewOptions.byTag',
    tag_plural: '@:glossary.tag_plural',
    allTasks: 'All tasks',
    onlyActive: 'Active',
    onlyDone: 'Done',
    inbox: 'Inbox',
    outbox: 'Outbox',
  },
  finished: 'Finished',
  thisWeek: 'This week',
  nextWeek: 'Next week',
  thisMonth: 'This month',
  nextMonth: 'NextMonth',
  project: 'Project',
  notAuthorized: 'Contact your @:featuresTerms.teamAdmin admin to add new or change existing rules',
  noTaskChangeRights_section: 'You can not change @:glossary.project in task #{num}',
  noTaskChangeRights_tags: 'You can not change @:glossary.tag_plural in task #{num}',
  noTaskChangeRights_assignee: 'You can not change @:glossary.assignee in task #{num}',
  noTaskChangeRights_task_status: 'You can not change @:glossary.status in task #{num}',
  noTaskChangeRights_owner: 'You can not change @:glossary.owner in task #{num}',
  noTasksYet: 'You do not have any tasks yet',
  newColumnTooltip: {
    title: 'This column wasn\'t on the task desk before.',
    action: 'Click on the icon to fix its position or drag it to where you see fit.',
    caption: 'You can always change columns\' order later.',
  },
  nowReordable: {
    columns: 'You can now reorder columns. Try dragging them around!',
  },
  moveTo: {
    start: 'Move to start',
    end: 'Move to end',
  },
}
