export const blocking = {
  title: 'Financial suspension',
  description: 'The <b>{teamName}</b> team',
  description2: 'can be unblocked by its owner — {ownerName}.',
  descriptionOwner: 'As the owner of the team <b>{teamName}</b>',
  descriptionOwner2: 'you can unblock it in your Personal Account.',
  send: 'Contact support',
  goToLK: 'Go to Personal Account',
  faq: 'What is a Personal Account?',
  teamBlocked: 'Suspended',
  subjectLK: 'Financial account suspension ',
  activedTo: 'Active until {day}',
}
