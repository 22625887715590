export const chattape = {
  openChatProfile: 'Open profile',
  readOnly: 'Read-only chat',

  importantMessages: 'Important messages',
  settings: 'Settings',
  push: 'Push-notifications',
  unreads: 'Unread counter',
  replyTo: 'Reply to',

  callDetails: 'Active call info',

  formatOptions: {
    bold: 'bold',
    italic: 'italic',
    underline: 'underlined',
    strike: 'strikethrough',
    quote: 'blockquotes',
    code: 'code',
    pre: 'text\nblock',
    link: {
      description: 'description',
      protocol: 'https:',
      host: 'www.your.link',
    },
  },

  showMembers: 'Show group members',
  sendFile: 'Add file',
  sendTextBlock: 'Send text block',
  taskMessage: 'Message #{num}',
  meetingMessage: 'Message',
  groupMessage: 'Message #{name}',
  directMessage: 'Message {name}',
  typeMessage: 'Write a message...',
  enableNtf: 'Enable notifications',
  disableNtf: 'Disable notifications',

  selected: 'Selected',

  seemsLikeMicBlocked: 'Oops! It looks like you have denied microphone access',
  howToEnableMic: 'How to enable mic?',
  allowMicAccess: 'Please allow \nmic access',
  inSystemPreferences: ' in system preferences and&nbsp;reload app',
  shouldAllowMicAccess: 'To send Voice Message',
  howToEnableMicAfterBlock: 'How to allow microphone or camera access',
  enableMicStepOne: 'Click on microphone {0} at the right side of the&nbsp;address bar.',
  enableMicStepTwo: 'Choose <b>Always allow { url } to access your microphone</b> and click on&nbsp;<b>Done</b>.',
  temporaryDisabled: 'Blocked Temporarily',
  reloadPage: 'Refresh the page.',
  clickIconOnTheLeft: 'Click the icon {0} in the left part of the address bar',
  clickIconOnTheLeftMicro: 'Click on microphone {0} in the left part of the address bar',
  wantSaveChoose: 'If you want the&nbsp;browser to&nbsp;remember your choice for the&nbsp;microphone permission select <b>Remember this decision</b>.',
  micModal: {
    ff: 'Remove the microphone lock by clicking {0} next to {1}.',
    edge: 'At&nbsp;the&nbsp;next request for&nbsp;microphone access, select <b>Allow</b>.',
    opera: 'Select <b>Allow</b> next&nbsp;to <b>Microphone</b>.',
    safari_1: 'Select the&nbsp;menu <b>Safari</b> > <b>Settings for&nbsp;this&nbsp;website...</b>',
    safari_2: 'In&nbsp;the&nbsp;dialog box select <b>Allow</b> next&nbsp;to <b>Microphone</b>.',
    yandex: 'Turn&nbsp;on <b>Use microphone</b>.',
    mac: {
      system: 'Go to the&nbsp;<b>System preferences</b> and select <b>Security & Privacy</b>.',
      confid: 'In&nbsp;opened window click <b>Privacy<b/>.',
      micro: 'In&nbsp;the&nbsp;list on&nbsp;the&nbsp;left select <b>Microphone</b> or <b>Camera</b>.',
      checkMark: 'Find <b>tada.team</b> on&nbsp;the&nbsp;right and check the&nbsp;box next&nbsp;to the&nbsp;name.',
    },
    win: {
      search: 'Click on&nbsp;search and write microphone.',
      select: 'Select <b>Choose which apps can access your microphone</b>.',
      micro: 'In the opened window turn on both switches <b>Allow apps to access your microphone</b> and <b>Allow desktop apps to access your microphone</b>.',
      reload: 'Check in the&nbsp;same window that there is <b>tada.team</b> in the&nbsp;app list. If&nbsp;not, restart your computer.',
    },
  },

  directChat: 'Direct chat',
  messageFromDirect: 'Direct message',
  messageFromGroup: 'From group',
  messageFromTask: 'From task',

  unreadMessages: 'Unread messages',

  messageActions: {
    resend: 'Retry',
    task: '@:common.createTask',
    taskItem: 'Add to checklist',
    meeting: 'Create meeting',
    hidePreview: 'Hide preview',
    'smile-o': 'Add reaction',
    reply: 'Reply',
    forward: 'Forward to...',
    forwardHere: 'Forward here',
    copy: 'Copy text',
    important: 'Mark important',
    notImportant: 'Unmark important',
    edit: 'Edit',
    delete: 'Delete for everyone',
    debug: 'Rubber duck',
    trash: '@:chattape.messageActions.delete',
    pin: 'Pin message',
    unpin: 'Unpin message',
    comment: 'Comment',
  },
  isBot: 'Bot',

  importantHook: {
    curChat: 'This chat only',
    notFound: 'No important messages',
  },

  mainHookBtn: 'Jump to recent messages',
  stickers: '@:(glossary.sticker_plural)',
  attachFile: 'Add a file',
  userRecAudioMsg: 'recording a voice message...',
  userRecAudioMsgShort: 'is recording audio...',
  userRecAudioMsgShort_plural: 'are recording audio...',
  openedGroup: 'Public group',
  publicTask: 'Public task',
  publicMeeting: 'Public meeting',
  groupListOpen: 'Show @:featuresTerms.team list',
  groupListHide: 'Hide @:featuresTerms.team list',
  openInNewWindow: 'Open in new tab',

  selectedMessageBar: {
    canForwardMessages: 'Cannot forward more than {max} messages',
    cannotDelete: 'Cannot delete old messages or messages sent by other people',
  },

  changeMessage: {
    moreInfo: 'More info... ',
    emptyField: 'N/A',
  },

  untitledFile: 'Untitled',
  textBlockExpand: 'Show more',
  textBlockCollapse: 'Show less',

  reactionRecentlyUsed: 'Recently used',
  reactionAll: 'Reactions',
  reactionAdd: 'Add reaction',

  deleteMessagesTitle: 'Delete messages',
  deleteMessagesText: 'Do you want to delete selected messages for everyone?',
  deleteOneMessageTitle: 'Delete message',
  deleteOneMessageText: 'Do you want to delete this message for everyone?',

  messageIsEdited: '(edited)',

  forwardedMessages: 'forwarded message',
  forwardedMessages_plural: 'forwarded messages',

  dropTaskHint: 'Drop task here to send a link to it',
  dropFileHint: 'Drop files here',

  goToMessage: {
    title: 'Go to chat',
    textHTML: 'Do you want to go to this message in <b>{sourceName}?',
    go: 'Go',
  },

  emoji: {
    smileysPeople: 'Smileys & people',
    animalsNature: 'Animals & Nature',
    objects: 'Objects',
    foodDrink: 'Food & Drink',
    activitySport: 'Activity & Sport',
    travelPlaces: 'Travel & Places',
  },

  sendMessage: {
    title: 'Send message options:',
    enterLine1: 'Enter – send message',
    enterLine2: 'Shift + Enter – new line',
    ctrlEnterLine1: 'Ctrl + Enter, Cmd + Enter – send message',
    ctrlEnterLine2: 'Enter – new line',
  },

  file: {
    open: 'Open',
    preview: 'Preview',
    retry: 'Retry',
    showInFinder: 'Show in Finder',
    showInFolder: 'Show in folder',
  },
}
