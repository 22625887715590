export const calendar = {
  documentTitle: 'Calendar',
  year: 'Year',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  required: 'Required',
  notRequired: 'Not required',
  membersSelector: {
    placeholder: 'My calendar',
  },
}
