export const calls = {
  callCard: {
    callType: {
      normal: 'Active call ',
      group: 'Active group call',
    },
  },

  callDetails: {
    info: 'Active call',
    calling: 'Calling',
    reconnecting: 'Reconnecting...',
    audioRecorded: 'Recording call',
    audioNotRecord: 'Record Call',
    callEnded: 'Call ended',

    user_0: 'member',
    user_1: 'members',
    user_2: 'members',

    camDenied: 'No access to camera',
    micDenied: 'No access to microphone',
    bothDenied: 'No access to microphone or camera',
    howToEnable: 'How to enable?',
  },

  notifyCallBar: {
    connect: 'Join call',
    accept: 'Accept',
    reject: 'Reject',
    cancel: 'Cancel',
    disconnect: 'Disconnect',
    activeCall: 'Active call',
    initPeer: 'Initializing connection...',
    initMic: 'Requesting microphone permission...',
    processConnection: 'Connectiong...',
    waitAnswerState: 'Waiting for server...',
    connected: 'Connected',
    connectErr: 'Connection error',
  },

  callAlerts: {
    sureDisconnect: 'Your action will interrupt the current call. Are you sure you want to continue?',
  },

  openChat: 'Open chat',
  muteAll: 'Mute everyone',
  record: {
    start: 'Record call',
    stop: 'Stop recording',
  },
  mute: {
    on: 'Unmute',
    onFullscreen: 'Unmute — Space',
    off: 'Mute',
  },
  volume: {
    on: 'Volume on',
    off: 'Volume off',
  },
  video: {
    on: 'Camera on',
    off: 'Camera off',
  },
  screensharing: {
    on: 'Start screensharing',
    off: 'Stop screensharing',
  },
  isCalling: '{name} is calling',
  inviteMembers: 'Invite members',
  membersLimit: 'Max members limit reached',
  addMembers: {
    title: 'Invite members',
    caption: 'Selected {selected} of {of}',
    loadingMembers: 'Loading members',
    noSearchResults: 'No results',
  },
  tooManyReconnects: 'Call ended: could not reconnect.',
  inCall: 'Connected: {num} of {total}',
  callActionsBtn: {
    alreadyConnected: 'You are already in a call in this chat',
    activeCallElsewhere: 'You are already connected to a call in another chat',
    connectToCall: 'Connect to an ongoing call',
    startCall: {
      title: 'Call',
      silent: {
        title: 'Create conference room',
        description: 'No notifications will be sent to others',
      },
      loud: {
        title: 'Start conference call',
        description: 'Every chat member will receive an incoming call notification',
      },
    },
  },
  screenSizes: {
    full: 'Fullscreen',
    middle: 'Medium mode',
    mini: 'Minimized',
  },
  userMediaPreference: {
    audio: 'Audio only',
    video: 'Video only',
    both: 'Audio & video',
    none: 'None',
  },
  errors: {
    noMediaAccess: {
      title: 'Couldn\'t get access to media device',
      caption: 'You could\'ve denied access to media or device of selected type is not available in your system',
    },
  },
  membersLayout: {
    toggleList: 'Members list',
    toggleCards: 'Members cards',
  },
  lgLayout: {
    toggleGrid: 'Grid layout',
    togglePresenter: 'In-focus layout',
  },
}
