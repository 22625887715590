export const teamsBar = {
  expand: 'Expand',
  collapse: 'Collapse',
  newTeam: 'New team',
  menu: {
    dashboard: 'Dashboard',
    dashboardClose: 'Close dashboard',
    calendar: 'Calendar',
    calendarClose: 'Close calendar',
    calendarTooltip: 'Calendar — {n} meetings this week ' +
      '| Calendar — {n} meeting this week ' +
      '| Calendar — {n} meetings this week',
    calendarBlockedTooltip: 'The team\'s calendar is blocked',
    chatList: 'Chats',
  },
  teamMenu: {
    addContact: 'Add contact',
    integrations: 'Integrations',
    invite: {
      text: 'You were invited to team',
      accept: 'Accept',
      decline: 'Decline',
    },
    profile: 'Team profile',
    settings: 'Settings',
  },
  help: {
    label: 'Help',
    menu: {
      manual: 'Manual',
      releaseNotes: 'Release notes',
      support: 'Support',
      wishlist: 'Wishlist',
    },
  },
}
