export const chatlist = {
  unnamedContact: 'Unnamed contact',
  unnamedTask: 'Untitled task',
  unnamedGroup: 'Untitled group',
  unnamedMeeting: 'Untitled meeting',
  searchPlaceholder: 'Search',
  newMessages: 'New messages',
  noGroupChats: 'You have no groups',
  noDirectChats: 'You have no direct chats',
  listCollapse: 'Show less',
  listExpand: 'Show all groups',
  createChat: 'Create direct chat',
  createGroup: 'Create new group',
  joinGroup: 'Join public group',
  groupChats: 'Groups',
  directChats: 'Direct Chats',
  appearance: 'Appearance',
  appearanceCompact: 'Compact view',
  appearanceOptions: {
    compact: 'Compact',
    extended: 'Extended',
  },
  sorting: 'Sorting',
  sortingOptions: {
    default: 'By activity',
    name: 'By name',
  },
  displayUnreadFirst: 'Display unread first',
  createDialog: 'Start a conversation',
  createDialogWith: '@:chatlist.createDialog with {name}',
  noMessages: 'No messages',
  messageDeleted: 'Deleted message',

  writing: 'is typing...',
  writingNY: 'is writing a letter to Santa...',
  writing_plural: 'are typing...',
  writingNY_plural: 'are writing letters to Santa...',
  you: 'You',
  draft: 'Draft',
  chatMenu: {
    pinChat: 'Pin to top',
    unpinChat: 'Unpin',
    hideChat: 'Hide this chat',
  },
}
