export const profiles = {
  unknownUser: 'Unknown user',
  unknownGroup: 'Unknown group',
  privateGroup: 'Private channel',
  noRole: 'Not specified',
  sendMsgTo: 'Message',
  addTask: 'Create task',
  showTasks: 'Show tasks',
  phone: 'Phone',
  phoneCaption: 'Contact phone number, not used to&nbsp;log in&nbsp;to&nbsp;service.<br>To&nbsp;change login phone number, write to&nbsp;Support chat',
  mobile_phone: 'Mobile phone',
  company: 'Company',
  department: 'Department',
  autoDownloadDesktop: 'Auto download files',
  notSpecified: 'Not specified',
  theme: 'Theme',
  language: 'Language',
  removeFromTeam: 'Remove from @:featuresTerms.team',
  lookupFiles: 'Files',
  addToGroup: 'Add to group...',
  addToGroups: {
    caption: 'Add {- nameHTML } to groups',
    searchPlaceholder: 'Find group',
    searchEmptyMessage: 'No groups found',
  },
  kicked: 'Removed from this team',
  kick: {
    caption: 'Remove contact from this @:featuresTerms.team',
    content: 'Are you sure you want to remove #[name] from this @:featuresTerms.team?',
    acceptLabel: 'Remove',
    acceptError: 'Error removing contact, please try again',
  },

  notifications: {
    all: 'Push-notifications',
    error: 'Error changing notification settings, please try again',
    groups: 'Group notifications',
    tasks: 'Tasks notifications',
    meetings: 'Meetings notifications',
    captionDisabled: 'Push notifications are disabled for entire service.<br>You can enable it&nbsp;in&nbsp;"My&nbsp;Profile" menu',
    systembot: 'TadaBot notifications',
    reactions: 'Reaction notifications',
  },

  groupProfile: 'Group Profile',
  lookEveryone: 'Show all',
  makeAdmin: 'Grant admin rights',
  makeMember: 'Remove admin rights',
  excludeContact: {
    caption: 'Remove from group',
    content: 'Are you sure you want to remove #[c] from group #[g]?',
    acceptLabel: 'Remove',
    acceptError: 'Error removing user from a group, please try again',
  },

  teamProfile: '@:featuresTerms.team profile',
  singleGroupTeamProfile: '@:glossary.singleGroupTeam profile',
  teamCreator: '@:featuresTerms.teamOwner',
  contactsAmount: 'Members',
  usage: 'Disk space usage',
  leaveTeam: {
    caption: 'Leave @:featuresTerms.team',
    content: 'Are you sure you want to leave @:featuresTerms.team #[name]?',
    acceptLabel: 'Leave',
    acceptError: 'Error leaving @:featuresTerms.team, please try again',
    contentOwner: 'You are the owner of the "@:featuresTerms.teamV #[name]" team, so you cannot leave it. The team owner can be changed by contacting the Support chat.',
    acceptLabelSupport: 'Support',
    messageForSupportChat: 'You are the owner of the team, so you cannot leave it. The team owner can be changed by contacting the Support chat..',
  },

  leaveGroup: 'Leave group',
  goToChat: 'Go to chat',
  removeTeamMember: {
    title: 'Remove member from @:featuresTerms.team',
    html: 'Are you sure you want to remove <b>#[displayName]</b> from this @:featuresTerms.team?',
  },

  faviconSetting: 'Show @:featuresTerms.team avatar as&nbsp;favicon',
  autoLaunch: {
    title: 'Open app automatically at&nbsp;startup',
  },
  hiddenLaunch: {
    title: 'Open app minimized',
  },
  logLevel: {
    title: 'Logging level',
    caption: '<b>Trace</b> — maximum',
  },

  asteriskMentionOption: {
    html: 'Use * as mention key in&nbsp;chats',
    caption: '@ also continues to work',
  },

  altSendOption: {
    title: 'Send message with&nbsp;{key}+Enter',
    caption: 'New line with Enter',
  },
  resetAllSettingsText: 'Clear settings for ALL teams',
  resetCurrentTeamSettingsText: 'Clear settings for current team',
  setStatus: 'Set status',
  changeStatus: 'Change status',
}
