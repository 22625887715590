export const auth = {
  loginOptions: {
    byPassword: 'By username',
    bySMS: 'By phone number',
    thirdParty: 'Via external',
  },

  username: 'User name',
  password: 'Password',
  canNotBeEmpty: 'Сan not be empty',
  usernameOrPasswordError: 'Login error. Please, check your username and password',
  stdError: 'An error has occurred, please try again',

  title: 'Sign in',
  description: 'Please choose your country and enter your full phone number',
  descriptionEnterButton: 'By clicking "{buttonLabel}", you agree to our',
  logInDescription: 'We\'ve sent an SMS with an activation code to your phone.\nPlease enter the code below',
  terms: 'Terms of Service',
  resentText: 'You can re-send your activation code in ',
  resendedSmsText: 'SMS with new activation code is sent',
  resendBtn: 'Re-send code',
  phoneInputPlaceholder: 'Enter your code',
  receivePassBtn: 'Next',
  logInBtn: 'Log In',
  changePhoneBtn: 'Edit phone number',
  enterByPasswordButton: 'Login',

  security: 'Security',

  twoFactorAuth: {
    passDeleted: 'Two-step authentication password deleted',
    passEstablished: 'Password set',
    passChanged: 'Password changed',
    passDrop: 'Password reset',
    passDeleteApprove: 'Remove your password?',
    title: 'Two-step authentication',
    passDefaultLabel: 'Password should be at least 6 characters long',
    passStepTitle: 'Pick Password',
    mailStepTitle: 'Recovery Mail',
    mailChangedTitle: 'Mail changed',
    mailChanged: 'Email changed successfully',
    mailCodeSent: 'Сonfirmation code has been sent to <br/><b>{email}</b>',
    mailCodeSentLogin: 'One-time code has been sent to <b>{email}</b> to reset password',
    inputMailLabel: 'Enter your email for reseting password',
    noneMailWarning: 'If you don\'t fill&nbsp;in an&nbsp;email and forget your password, you will lose access to&nbsp;your account forever!',
    noneMailAcceptRisk: 'I understand risk, {actionLabel}',
    removeMailAction: 'delete email',
    noneMailAction: 'continue without email',
    emailTitle: 'Email',
    codeInputTitle: 'Verification code',
    codeSentAgain: 'New code can be requested in',
    sendAgain: 'Send again',
    changePass: 'Change Password',
    discardPass: 'Discard Password',
    forgotPasswordQuestion: 'Forgot your password?',
    changeEmail: 'Change email',
    backToSettings: 'Back to settings',
    continue: 'Continue',
    createPassBtn: 'Set password ',
    authEnabled: 'Two-Step Verification is enabled. The account is password-protected',
    previewStepText: 'Set additional login password to make your account protection stronger. You have to enter it when logging in from any device in addition to SMS code',
    defaultText: 'You have to enter this password when logging in from new device in addition to SMS code',
    hintText: 'Helps you remember specified password',
    hintPlaceholder: 'Hint (optional)',
    repeat: 'Repeat',
    disablePass: 'Remove',
    cancelRemove: 'Don\'t remove',
    hint: 'Hint',
    authDisableWarnText: 'Disabling two-step authentication reduces your account protection',
    forgotPasswordError: 'You did not provide email for reseting password. You can only log in to account by remembering your password',
  },

  newLogin: {
    noPassword: 'Password not entered',
    noLogin: 'Login not entered',
    noLoginAndPassword: 'Login and password not entered',
    noCode: 'Code not entered',
    noPhone: 'Phone not entered',
    unknownSecondFactor: 'Unknown two-factor authentication method',
    usualServer: 'Cloud server',
    corpServer: 'Corporate server',
    connect: 'Connect',
    getCode: 'Get the code',
    authTitle: 'Authorization',
    serverTitle: 'Server address',
    discardPassTitle: 'Password reset',
    secondTitle: 'Additional password',
    support: 'Support',
    faq: 'FAQ',
    phoneLabel: 'Select country and enter phone number',
    number: 'Number',
    phone: 'Phone',
    login: 'Login',
    password: 'Password',
    chooseCountry: 'Select country',
    termsText1: 'terms of use',
    termsText2: 'privacy policy',
    checkSms: 'Check code in&nbsp;SMS',
    corpServerLabel: 'Enter address of corporate server',
    tfaLoginLabel: 'Enter your account password',
    onEmailCodeSent: 'Code to restore access was sent to <br/><b>{email}</b>',
    emaily: 'email',
    and: 'and',
  },

  newOnboarding: {
    teamCreation: 'Create team',
    teamEditing: 'Edit team',
    teamDataCanBeChanged: 'You can always rename team and change avatar in&nbsp;settings',
    profileDataCanBeChanged: 'You can always change your personal information and avatar in&nbsp;your profile settings',
    noTeamName: 'Enter team name, maximum 200&nbsp;characters',
    aboutUser: 'Introduce yourself',

    mobileLabel: 'Install&nbsp;mobile app&nbsp;tada.team and&nbsp;start&nbsp;chatting',
    mobileInstall: 'Install',
    mobileToWeb: 'Continue in browser',

    noInvitationsHeader: 'No invitations',
    noInvitationsBody: 'No teams to join. We will notify you as soon as they appear',
    createTeam: 'Create team',
    addToTeamHeader: 'Invite colleagues',
    addToTeamBody: 'Send invitation to your colleagues to team <b>{teamName}</b>',
    addToTeamLabel: 'You can always invite your colleagues to team after creation',
    skipStep: 'Skip step',
    teamsManagmentHeader: 'Administrating teams',
    teamsManagmentBody: 'If your company already uses tada.team, we will help you join it. Or create your own team and invite colleagues',
    invitations: 'Invitations',
    declineInvite: 'Decline',
    acceptedInviteBody: 'Invitation is accepted. Go to the team and start chatting',
    defaultInviteBody: 'You were invited to team </b>{teamName}.</b>\n Accept invitation?',
    moveToTeam: 'Move to team',
  },

  change_server: 'Change server',
  change_server_prompt: 'Are you sure you want to switch server? You will be able to access current server by clicking on "Server" in the app menu',
  change_server_confirm: 'Change',
  use_custom_server: 'Use company server',
  newServerError: 'Server address seems to be incorrect. Please check the address or contact you system administrator',
  sameServerError: 'You are already using this server',
  using_custom_server: 'You are using company server',

  onboardProfile: {
    titleIsInvitation: 'Check profile info',
    title: 'Edit profile',
    description: 'It will be easier for other @:featuresTerms.team members to recognize you\nif you put a profile photo',
    uploadAvatarInfo: 'Drag or click here to upload your avatar',
    uploadAvatarFormat: 'Supported formats: png, jpg, jpeg, gif.',
    name: 'First name',
    lastName: 'Last name',
    patronymic: 'Patronymic',
    descInfo: {
      part1: 'You can change this information',
      part2: 'later in settings',
    },
    backCommandsBtn: 'Back to your @:featuresTerms.teams',
    enterCommandBtn: 'Join @:featuresTerms.team',
    createCommandBtn: 'Create a @:featuresTerms.team',
  },

  captcha: {
    errors: {
      loading: 'Error loading reCAPTCHA',
      token: 'Error obtaining reCAPTCHA token',
    },
  },
}
