export const tasks = {
  from: 'From',
  to: 'To',
  assignedToMe: 'Assigned to me',

  openFullForm: 'Open full form',
  whatToDo: 'What needs to be done?',
  newTaskItemPlaceholder: 'Write what needs to be done, or add a subtask, for example — #{num}',

  newTaskBtn: 'New task',
  backToActive: 'Back to Active Tasks',
  taskSearch: 'Search tasks',
  searchEverywhere: 'Search everywhere',
  nothingInTab: 'No results in tab {tab}',

  startTyping: 'Start typing...',

  commentsClosed: 'Task is completed, comments are closed',

  taskIsDone: 'completed',
  makeOpen: 'Reopen',
  makeDone: 'Complete',
  edit: 'Edit',
  delete: 'Delete',
  closeWindow: '@:common.closeWindow',
  created: 'Created',
  fromForCreated: 'from',

  dependentTask: 'Uptask',
  dependentTask_plural: 'Uptasks',
  dependentTaskTooltip: 'Execution of these tasks depends on the current task',
  assignee: 'assignee',
  member: 'member | member | members',
  member_plural: 'members',

  notAssigned: 'None',
  noMembers: 'No members',

  tags: '@:glossary.tag_plural',

  public: 'task is public',
  public_tooltip: 'Visible to all @:featuresTerms.team members. When created from a message in any chat, a link to the task is sent to that chat',

  hideTaskDescription: 'Hide task description',

  remindersTooltip: {
    listTitle: 'Your reminders:',
    howToManage: 'To manage reminders open task editing',
  },

  createdFrom: 'Created from {0} {1}',
  fromMessage: 'message',
  fromDirectMessage: 'direct message',
  createdFromMessageDescription: 'Message from @{name} in {chat}, {date}',
  createdFromMessageInDirectChatDescription: 'Message from @{name} in chat with {chat}',
  createdFromMessageInNonDirectChatDescription: 'Message from @{name} in {chat}',

  pinnedTabs: 'Pinned tabs',
  recentTabs: 'Recent tabs',
  pressToPinTip: 'Click on {0} on the tab from the list of recent ones below to pin it.',
  useFiltersTip: 'Here you will see recently used task filters. Click on {0} to use filters.',

  taskType: 'Task Type',
  typeIncoming: 'Incoming',
  typeAssignedToMe: 'Assigned to me',
  typeOutgoing: 'Outgoing',
  typeMember: 'Member',

  taskStatus: 'Task Status',
  statusNew: 'Open tasks',
  statusDone: 'Comleted tasks',

  tabDefault: 'Active tasks',
  tabIncoming: 'Incoming',
  tabOutgoing: 'Outgoing',
  tabAssigned: 'Assigned to me',
  tabDone: 'Archive',

  tabCannotPin: 'Can not pin more than 5 tabs',

  people: 'People',
  allMembers: 'Including all members',

  errorNotFound: 'Task not found',
  errorAccessDenied: 'The task is private, only its participants have access.<br />Task creator can add to the task',
  errorUploadingFiles: 'Task created successfully, but not all files are uploaded. Please try again.',
  errorChangingStatus: 'Error changing task status, please try again.',

  deleteTitle: 'Delete task',
  deleteQuestion: 'Are you sure you want to delete task <b><q>{task}</q></b>?',
  deleteYes: 'Delete',

  editTask: 'Edit task',
  newTask: 'New task',
  taskCreator: 'Task creator',
  removeAssignee: 'Unassign',
  toSetAssigneeHTML: 'Drag a member here to assign<br>&nbsp;',
  myReminders: 'Reminders',
  noReminders: 'No reminders',
  clickToAdd: 'Click to add',
  addReminder: 'Add Reminder',
  dropFilesTip: 'Drag files or click here',
  dropFileTip: 'Drop file to add it to task',
  mustHaveDescription: 'Please enter task description',

  deadline: {
    basic: 'Deadline',
    longer: 'Deadline',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
    week: 'In a week',
    no: 'No deadline',
    clickToSet: '(click to set)',
    overdue: 'Overdue',
  },

  addTag: 'Add tag',
  searchPlaceholder: 'Search',
  noneSelected: 'None',

  searchMembers: 'Search @:featuresTerms.team members',

  atTime: 'at',

  taskClosed: 'Task is completed',

  noTasks: 'No tasks',

  taskItems: {
    title: 'Checklist',
    hideCompleted: 'Hide completed items',
    showCompleted: 'Show completed items',
    itemDeleted: 'Item deleted',
    restore: 'Restore',
    close: 'Close',
    addItem: 'Add item',
    deleteItem: 'Delete item',
    addCheckList: 'Add checklist',
    networkErr: 'Error occured. Please check your internet connection and try again',
    maxLengthItem: 'Maximum length of item description is {max} characters',
    unsavedItem: 'You have unsaved edits on this field',
    unsavedItemEdit: 'View edits',
    unsavedItemDiscard: 'Discard',
    copySubtask: 'Create copy task',
    captionCreate: 'Create task',
  },

  taskDesk: {
    title: 'Tasks',
    label: 'Dashboard',
  },

  filters: {
    title: 'Filters',
    allProjects: 'All projects',
    noProject: 'No project',
    noImportance: 'Without priority',
    addProject: 'Create a new project...',
    apply: 'Apply',
    project: 'Project',
    assignee: 'Assigned to',
    assignee_plural: 'Assigned to',
    owner: 'Created by',
    owner_plural: 'Created by',
  },

  createCopy: 'Create copy',

  importanceCaption: {
    ascending: 'Lower - more important',
    descending: 'Higher - more important',
  },

  draft: {
    caption: 'Data was restored from last draft.',
    action: 'Remove draft.',
  },

  errorMessages: {
    403: 'It is private chat, only chat participants have access.<br />Chat administrator can add new participants',
  },
}
