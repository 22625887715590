export const errors = {
  chromeWsErrorHTML: 'Some VPN/proxy browser extenstions may interfere <br> with the normal operation of the app and some other websites.<br> If this message does not dissappear, please try to disable such extenstions.',
  sendingError: 'Error sending message, please try again',
  implicitError: 'An error has occurred, please try again',
  EMPTY_TOKEN: 'Request sent with empty token. Try to logout and log back in again.',
  INVALID_TOKEN: 'Request sent with an invalid token. Try to logout and log back in again.',
  ACCESS_DENIED: 'You do not have access to perform this action. Try contacting your @:(featuresTerms.teamAdmin).',
  NOT_FOUND: 'Requested data was not found.',
  INVALID_METHOD: 'HTTP-method is not supported.',
  INVALID_DATA: 'Incorrect data format.',
  RATE_LIMIT: 'Too many server requests. Try again later.',
  INTERNAL_SERVER_ERROR: 'Internal server error. Try refreshing the page. If the problem persists please contact support {email}',
  unknownError: 'Unknown error. Try refreshing the page. If the problem remains please contact support {email}',
}
