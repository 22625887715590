export const common = {
  nothing: 'No Results',

  changeCountry: 'Select a country',
  personalAccount: 'Personal Account',

  save: 'Save',
  yes: 'Yes',
  no: 'No',
  add: 'Add',
  create: 'Create',
  retry: 'Retry',
  cancel: 'Cancel',
  close: 'Close',
  error: 'Error',
  back: 'Back',
  delete: 'Delete',
  remove: 'Remove',
  showAll: 'Show All',
  move: 'Go to',
  clear: 'Clear',
  change: 'Change',
  edit: 'Edit',
  or: 'or',
  processing: 'processing...',

  description: 'Description',
  role: 'Position',
  loading: 'Loading...',

  admin: 'Administrator',
  guest: '@.capitalize:featuresTerms.teamGuest',
  teamOwner: '@.capitalize:featuresTerms.teamOwner',
  bot: 'Bot',
  you: 'You',

  tutorial: 'Tutorial',
  readonly: 'Read-only',

  from: 'From',
  to: 'To',
  at: 'at',
  since: 'After',
  until: 'Before',
  today: 'Today',
  yesterday: 'Yesterday',

  search: 'Search',

  settings: 'settings',
  globalSettings: 'Global settings',
  appSettings: 'App settings',
  debugSettings: 'Debug settings',
  teamSettings: '@:featuresTerms.team specific settings',
  singleGroupTeamSettings: '@:glossary.singleGroupTeam specific settings',

  groupSettings: 'Group Settings',
  joinGroup: 'Join public group',
  leaveGroup: 'Leave group',
  leaveTeam: 'Leave @:featuresTerms.team',
  leaveSingleGroupTeam: 'Leave @:glossary.singleGroupTeam',
  addMembers: 'Add members',
  removeMember: 'Remove from group',
  openGroupList: 'Public Groups',
  openProfile: 'Show Profile',
  addContact: 'Add contact',
  openChat: 'Go to chat',
  writeMessage: 'Message',
  openCalendar: 'Calendar',
  openMyCalendar: 'My calendar',
  createTask: 'Create task',
  callTo: 'Call',

  online: 'Online',
  offline: 'Offline',
  wasOnline: 'Last seen {ago}',

  connectionTroubles: 'Connection interrupted...',
  tryReconnect: 'Reconnect',

  openTaskDialog: {
    caption: 'Go to task',
    quest: 'Do you want to go to task ##[num]?',
  },

  stateLoadMe: 'Loading user information...',
  stateLoadSettings: 'Loading user settings...',
  stateLoadRoster: 'Loading @:featuresTerms.team settings...',
  stateLoadTasks: 'Updating tasks...',
  stateLoadTime: 'Synchronization...',

  avaText1: 'Change',
  avaText2: 'avatar',

  uploadImageText: 'Drag or click here to upload. \n Supported formats: png, jpg, jpeg, gif.',
  uploadImageText2: 'Drag or click here to upload. Supported formats: png, jpg, jpeg, gif.',

  dropDownNotSelected: 'Not selected',

  andMore: '...and {left.length} more',

  controlKey: 'Ctrl',
  commandKey: 'Cmd',

  canChangeIn: 'Can change in',

  accept: 'Accept',
  name: 'Name',

  day_short: 'd.',
  hour_short: 'h.',
  minute_short: 'm.',

  validationErrors: {
    number: 'Must be a number',
    nonNegativeNumber: 'Can\'t be below 0',
    integer: 'Must be an integer',
    between: 'Must be from {from} to {to}',
    maxLength: 'Max {to} symbols',
  },

  next: 'next',

  selectFile: 'Select file',
  closeWindow: 'Close window',
  comingSoon: 'Coming soon',
  notRequired: 'Not required',
  copied: 'Copied',
  contactSupport: 'Contact support',
  department: 'Department',
  project: 'Project',
  without: 'without',
  pin: 'Pin to top',
  unpin: 'Unpin',
  hide: 'hide',
  showMore: 'show more',

  withoutProject: '@:common.without @:glossary.project',
  withoutTags: '@:common.without @:glossary.tag_plural',
  withoutImportance: '@:common.without @:glossary.importance',
}
