export const importChat = {
  title: 'Import group from Telegram',
  closeTitle: 'Close and come back later',
  open: 'Import from Telegram',
  errorCaption: 'Error',
  steps: {
    load: 'File upload',
    check: 'File check',
    import: 'User mapping',
    done: 'Chat creation',
  },
  step1: {
    processLabel: '{loadingSize} from {fileSize}',
    description: {
      tooltip: 'For macOS download and install Telegram from the official website of developer — ' +
          'chat export is only available in this version',
      title: 'First, export the group from Telegram',
      1: 'Open group in&nbsp;Telegram that you want to&nbsp;transfer to&nbsp;tada.team',
      2: 'Click on&nbsp;the "more" icon on&nbsp;the&nbsp;top right',
      3: 'Select "Export Chat History"',
      4: 'Select the export format — <b>Machine-readable JSON</b>',
      5: 'Click "Export"&nbsp;— folder with data "ChatExport_2022-MM-DD" will be saved',
      6: 'Check that the folder contains the "result.json" and optionally subfolders with documents',
      7: 'Pack the folder "ChatExport_2022-MM-DD" into one <b>.zip</b> archive',
    },
    upload: {
      text: '...then drag and drop .zip archive here',
      textError: 'This is not a .zip',
      textCountError: 'You have added more than 1 file',
      or: 'or',
      warning: 'We accept only .zip format',
      countWarning: 'You can download only 1 zip file',
      load: 'Upload file',
      repeatLoad: 'Upload again',
      loading: {
        title: 'Upload the file to the server. Do not close the window, please, otherwise you will have to start over',
        titleDone: 'File is uploaded to server',
        titleError: 'File is not uploaded',
      },
      remove: 'Delete file',
      cancel: 'Cancel upload',
    },
    checkData: 'Check data',
  },
  step2: {
    title: 'Unpacking and checking archive {fileName}',
    description: 'Can take from a few minutes to hours.<br/>' +
        'You can close the window and come back later.<br/>' +
        'All data will be saved',
    // TODO: titleError: 'В архиве нет json формата',
    // TODO: descriptionError: 'Посмотрите инструкции ещё раз, мы принимаем только json формат файлов',
    titleDone: 'Archive has been unpacked and checked',
    descriptionDone: '',
    cancelImport: 'Cancel import',
    next: 'Nex',
    repeat: 'Start again',
  },
  step3: {
    info: 'It is necessary to&nbsp;match users from Telegram and tada.team to&nbsp;show the chat history correctly. </br>' +
        'If the user is not in tada.team, add him to the team in ActiveDirectory or via the tada.team interface <br/>' +
        'You can close the window and return to the mapping after adding users',
    errorCaption: 'Error',
    back: 'Back',
    repeat: 'Repeat again',
    chatInfo: {
      name: 'Group name',
      public: 'This is private group',
      info: 'You can change the publicity in the chat settings after the import is completed',
    },
    generation: {
      title: 'Creating group',
      description: 'Process will take from a few minutes to an hour',
    },
    users: {
      placeholder: 'User',
      nik: 'Nickname from Telegram',
      id: 'ID from Telegram',
      admin: 'Group admin',
      contact: 'Name in tada.team',
    },
    processedLabel: '{count} of {total}',
    errorLabel: 'Selected {count} {name}',
  },
  step4: {
    title: 'Group {chat} added',
    description: 'You can change settings in the main interface of tada.team',
    goTo: 'Go to group',
    nextImport: 'Import another chat',
  },
}
