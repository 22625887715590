export const glossary = {
  everything: 'all',

  contact_plural: 'contacts',
  contact: 'contact',
  contactR: 'contact',
  contactD: 'contact',
  contactV: 'contact',
  contactT: 'contact',
  contactP: 'contact',

  contacts: 'contacts',
  contactsR: 'contacts',
  contactsD: 'contacts',
  contactsV: 'contacts',
  contactsT: 'contacts',
  contactsP: 'contacts',

  free_male_R: 'free',
  free_plural_R: 'free',

  workingPlace_plural_R: 'positions occupied',
  workingPlace_plural_D: 'positions occupied',

  workingPlace: 'position occupied',
  workingPlace_R: 'positions occupied',

  task: 'task',
  task_plural: 'tasks',

  file: 'file',
  file_plural: 'files',

  meetings_plural: 'meetings',

  group: 'group',
  group_plural: 'groups',
  groupNew: 'group',
  groupNew_plural: 'groups',

  notification: 'notification',
  notifications: 'notifications',

  people: 'People',

  match: 'result | result | results',
  match_plural: 'results',

  message: 'message | message | messages',
  message_count: '{n} message | {n} message | {n} messages',

  character: '{n} characters | {n} character | {n} characters',

  man: 'member | member | people ',
  man_plural: 'people',

  sender: 'from',

  member: 'member | members | members ',
  member_plural: 'members',

  image: 'image',
  profile: 'Profile',

  youAre: 'It\'s you',

  formatting: 'formatting',
  editing: 'editing',
  cancel: 'cancel',
  send: 'send',
  close: 'close',

  owner: 'owner',
  owner_plural: 'owners',
  admin: 'admin',
  admins: 'admins',
  members: 'members',

  team: 'team',
  teams: 'teams',

  chat: 'chat',

  singleGroupTeam: 'personal @:glossary.chat',

  teamOwner: '@:glossary.team @:glossary.owner',
  teamAdmin: '@:glossary.team @:glossary.admin',

  teamAdmins: '@:glossary.team @:glossary.admins',
  teamMembers: '@:glossary.team @:glossary.members',

  sticker: 'Sticker',
  sticker_plural: 'Stickers',

  project: 'project',
  project_plural: 'projects',

  tag: 'tag',
  tag_plural: 'tags',

  assignee: 'assignee',
  assignee_plural: 'assignees',

  status: 'status',
  status_plural: 'statuses',
  importance: 'priority',
  importance_short: 'p',
  importance_lowest: 'very low',
  importance_low: 'low',
  importance_medium: 'medium',
  importance_high: 'high',
  importance_highest: 'highest',
  complexity: 'complexity',
  complexity_short: 'c',

  more: 'more',
  urgency: 'urgency',
}
