export const chatsBar = {
  control: {
    title: 'Chats',
    menu: {
      groupBadges: 'Group counters',
      groupNotifications: 'Group notifications',
      shortView: 'Compact view',
      sortByName: 'By name',
    },
  },

  filter: {
    all: 'All',
    direct: 'Direct',
    group: 'Groups',
    hidden: 'Archive',
    unreadOnly: 'Unread',
    comments: 'Comments',
  },

  search: {
    placeholder: 'Search',
    menu: {
      direct: 'Direct chat',
      group: 'Group chat',
      join: 'Join public group',
      import: 'Import from Telegram',
    },
  },

  list: {
    messages: 'Message | Message | Messages',
    noMessages: 'No messages',
    menu: {
      addMembers: 'Add members',
      autocleanup: 'Autodelete messages',
      counter: 'Unread counter',
      hide: 'Archive',
      leave: 'Leave chat',
      markAsRead: 'Mark as read',
      notifications: 'Notifications',
      openInNewTab: 'Open in new tab',
      pin: 'Pin to top',
      profile: 'Profile',
      settings: 'Settings',
      unpin: 'Unpin',
    },
  },

  placeholder: {
    empty: 'No&nbsp;chats',
    emptyHidden: 'Archive is&nbsp;empty',
    emptyUnreadOnly: 'No&nbsp;new messages',
    unreadOnlyDone: 'You\'ve read everything and it\'s&nbsp;wonderful!',
    unreadOnlyEmpty: 'Chat list is&nbsp;empty, create new one and start messaging',
    hiddenHelp: 'To&nbsp;archive chat, click menu next to&nbsp;chat name and select',
    createDirectBtnLabel: 'Create direct chat',
    createGroupBtnLabel: 'Create new group',
  },
}
