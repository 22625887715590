export const team = {
  createTeamTitle: 'Create @:featuresTerms.team',
  createFirstTeamTitle: 'Let\'s create your first @:featuresTerms.team',
  teamNamePlaceholder: '@:featuresTerms.team name',
  teamSettingsChange: {
    part1: 'You can change this later',
    part2: 'in @:featuresTerms.team Settings',
  },
  updateTeamImage: 'Drop files here or click to upload.',
  acceptedImageFormats: 'Supported formats: png, jpg, jpeg, gif',
  createTeamBtn: 'Create @:featuresTerms.team',
  backToTeamListBtn: 'Back to your @:featuresTerms.teams',
  changeProfileBrn: 'Edit my profile',
  teamBar: {
    rights: 'You have administrator rights in this @:featuresTerms.team',
    createTeam: 'Create new @:featuresTerms.team',
    open: 'Open',
    create: 'Create',
  },
  teamManage: {
    caption: 'Manage @:featuresTerms.teams',
    containsTeam: 'Your @:featuresTerms.teams:',
    showBtn: 'Show all',
    hideBtn: 'Hide',
  },
  statistic: {
    statisticFromTeam: 'Team statistic ',
    month: 'Month',
    threeMonths: '3 Months',
    halfYear: 'Half a year',
    year: 'Year',
    noDataForPeriod: 'No data for the period',
    activeUsers: 'Active users by messages',
    totalUsers: 'Number of team members',
    newUsers: 'Number of new users per day',
    newMessages: 'Number of messages created per day',
    newCalls: 'Number of calls per day',
    open: 'Open statistics',
  },
  errorMessages: {
    403: 'You don\'t have access to the team. Only members have access. Please clarify the information about the team from the person who gave you the link to it. If the error persists, please write to support — <a href="mailto:{email}">{email}</a>.\n<a href="/">Go to main site</a>',
  },
  chatNotSelected: 'Select chat and start messaging',
}
