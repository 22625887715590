export const contacts = {
  title: 'Contacts',
  findContact: 'Find contact',
  addDepartment: 'Add department',
  addContact: 'Add contact',
  createSingleGroupTeam: 'New @:glossary.singleGroupTeam',
  emptyDepartment: 'No contacts in department',
  noContactsForSearchConditions: 'No contacts that meet search conditions',
  editDepartment: 'Edit department',
  deleteDepartment: 'Delete department',
  deleteText: 'Are you sure you want to delete department',
  yesText: 'Delete',
  showDeleted: 'Show deleted',
  moveDepartmentHere: 'Move department here',
}
