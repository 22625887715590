export const calendarIntegrations = {
  description_1: 'This is the link for your tasks with deadlines and meetings in&nbsp;<code style="font-size:100%;">tada.team</code> for importing into calendar services.',
  description_2: 'Copy&nbsp;and paste it&nbsp;into that service, where you want to&nbsp;see your tasks from <code style="font-size:100%;">tada.team</code>',
  description_3: 'Yandex determines the synchronization frequency automatically.<br>It can range from a few hours to two days.',
  linkHint: 'Copy link',
  copied: 'Copied',
  Yandex: {
    file_1: '/assets/i/calendar/yandex/en/01.png',
    img_caption_1: 'Login into <a href="https://calendar.yandex.ru" target="_blank">Yandex.Calendar</a>. In&nbsp;left column find "Subscriptions", click "+&nbsp;New&nbsp;Subscriptions"',
    img_alt_1: 'Yandex.Calendar interface',
    file_2: '/assets/i/calendar/yandex/en/02.png',
    img_caption_2: 'Paste your link into&nbsp;«Calendar site address». Choose name, customize the color and&nbsp;notofications. Click "Create" in the lower right corner',
    img_alt_2: 'New subscription interface',
    file_3: '/assets/i/calendar/yandex/en/03.png',
    img_caption_3: 'Tasks and meetings will appear within a&nbsp;few minutes',
    img_alt_3: 'Interface message about adding events',
    file_4: '/assets/i/calendar/yandex/en/04.png',
    img_caption_4: 'Tasks and meetings from <code style="font-size:100%;">tada.team</code> appeared in&nbsp;Yandex.Calendar',
    img_alt_4: 'Yandex.Calendar with tasks and meetings from tada.team',
  },
  Google: {
    file_1: '/assets/i/calendar/google/en/01.png',
    img_caption_1: 'Login into <a href="https://calendar.google.com/" target="_blank">Google calendar</a>. In&nbsp;left column find "Other calendars" and click &nbsp;«<span style="font-size:16px;">+</span>». In&nbsp;the&nbsp;menu click «From&nbsp;URL»',
    img_alt_1: 'Google calendar interface',
    file_2: '/assets/i/calendar/google/en/02.png',
    img_caption_2: 'Paste your link into&nbsp;"URL&nbsp;of&nbsp;calendar" and&nbsp;click "Add&nbsp;calendar"',
    img_alt_2: 'Calendar subscription interface',
    file_3: '/assets/i/calendar/google/en/03.png',
    img_caption_3: 'Calendar added. Tasks and meetings will appear within a&nbsp;few&nbsp;minutes. <br> <span style="color:red;">Events from any services created after importing the&nbsp;calendar to&nbsp;Google appear in&nbsp;it with&nbsp;a&nbsp;delay of&nbsp;several hours</span>',
    img_alt_3: 'Interface message about adding the calendar',
    file_4: '/assets/i/calendar/google/en/04.png',
    img_caption_4: 'Tasks and meetings from <code style="font-size:100%;">tada</code> appeared in&nbsp;Google calendar',
    img_alt_4: 'Google calendar with tasks from tada.team',
  },
  iCalendar: {
    img_caption_1: 'Open iCalendar. From the "File" menu, select "New calendar subscription..."',
    img_alt_1: 'iCalendar interface',
    img_caption_2: 'Paste your link into&nbsp;«URL-адрес календаря» and click "Subscribe".',
    img_alt_2: 'Calendar subscription interface',
    img_caption_3: 'Name calendar. Select the desired settings. Click&nbsp;«ОК».<br>Tasks and meetings will appear within a&nbsp;few&nbsp;minutes',
    img_alt_3: 'Calendar settings window',
  },
}
