export const modals = {
  commit: 'Save',
  createBtn: 'Create',
  cancelBtn: 'Cancel',
  closeBtn: 'Close',
  sendBtn: 'Send',
  approveBtn: 'Confirm',

  NewTask: {
    project: 'Project',
    noProject: 'No project',
    followers: 'Followers',
    status: 'Status',
  },
  RedirectMessagesModal: {
    placeholder: 'Your message (optional)',
    destinationCaption: 'Forward to',
    forward: 'Forward',
  },
  Integrations: {
    title: 'Integration management',
    connected: 'Connected',
    servicesList: 'Services list',
    connect: 'Connect',
  },
  IntegrationManagement: {
    titleEdit: 'Manage integration',
    titleAdd: 'Add an integration',
    selectChat: 'Data will be sent here',
    comment: 'Description',
    isEnabled: 'Enabled',
    manual: 'Quick start guide',
    deleteBtn: 'Delete integration',
    deleteTitle: 'Delete integration',
    deleteText: 'Are you sure you want to delete integration {name} in group {group}?',
  },
  CalendarIntegrations: {
    title: 'Calendar integration',
  },
  SectionModal: {
    new: 'New department',
    create: 'Create new department',
    edit: 'Edit department',
    name: 'department name',
    priority: 'Priority',
    tip: 'Drag with mouse or use keys',
    createError: 'Error creating department. Please try again',
    editError: 'Error editing department. Please try again',
  },
  EditContact: {
    caption: 'Edit profile',
    toSection: 'Department',
    giveRights: 'Give admin rights',
    fallbackError: 'Error editing profile. Please try again',
    guest: '@:featuresTerms.teamGuest',
    member: '@:featuresTerms.teamMember',
    admin: '@:featuresTerms.teamAdmin',
    owner: '@:featuresTerms.teamOwner',
  },
  Dialog: {
    logout: {
      caption: 'Log out',
      content: 'Are you sure you want to log out?',
      acceptLabel: 'Log out',
    },
    leaveGroup: {
      caption: 'Leave group',
      content: 'Are you sure you want to leave #[name]?',
      acceptLabel: 'Leave',
      acceptError: 'Error leaving group. Please try again',
    },
  },
  CreationTeam: {
    caption: 'New @:featuresTerms.team',
    captionSingleGroupTeam: 'New @:glossary.singleGroupTeam',
    updateImage: 'Drag or click here to upload image.\nSupported formats: png, jpg, jpeg, gif.',
    teamNamePlaceholder: '@:featuresTerms.team name',
    singleGroupTeamNamePlaceholder: 'Chat name',
    teamMembersAddTitle: 'Invite members',
    teamMembersAddDesc: 'New row appears automatically as you fill in previous one',
    createBtn: 'Create',
    createTeamError: 'Error creating a @:featuresTerms.team, please try again',
    uploadLogoTeamError: 'Error changing @:featuresTerms.team avatar, please try again',
    deleteLogoTeamError: 'Error removing @:featuresTerms.team avatar, please try again',
    contactsSelectLabel: 'Select contacts from your other teams',
    addByPhoneNumberOptionLabel: 'Or add by phone number',
  },
  TeamSettings: {
    caption: '@:featuresTerms.team Settings',
    captionSingleGroupTeam: '@:glossary.singleGroupTeam Settings',
    teamName: '@:featuresTerms.team name',
    singleGroupTeamName: 'Chat name',
    adminTitle: 'Administrators',
    ownerTitleWithName: 'Owner: {name}',
    adminAddBtn: 'Add administrators',
    editMsgSetting: {
      caption: 'Edit or delete messages in @:featuresTerms.team',
      disabled: 'Disabled',
      oneHour: '1 hour',
      oneDay: '1 day',
      noRestrictions: 'Always allowed',
      hint: {
        restricted: '@:glossary.member_plural can delete and edit their messages within specified period.',
        unrestricted: '@:featuresTerms.teamAdmins can always delete any messages.',
      },
    },
    deleteCommand: 'Delete this @:featuresTerms.team',
    deleteCommandSingleGroup: 'Delete this chat',
    deadlineTime: 'Default task deadline time',
    deleteDlg: {
      caption: 'Delete @:featuresTerms.team',
      content: 'Are you sure you want to delete @:featuresTerms.team #[name]?',
      acceptLbl: 'Delete',
      acceptErr: 'Error deleting a @:(featuresTerms.team). Please try again',
    },
    createTeamErr: 'Error editing a @:(featuresTerms.team). Please try again',
    uploadLogoTeamErr: 'Error uploading @:featuresTerms.team avatar, please try again',
    deleteLogoTeamErr: 'Error removing @:featuresTerms.team avatar, please try again',
    changeContactStatusErr: 'Error updating status of {name}',
    notFound: 'Nothing found',
    pushEnterToSelect: 'To select a contact, press',
    tabs: {
      general: 'General',
      tasks: '@:glossary.task_plural',
    },
    experimentalSettings: {
      title: 'Experimental settings',
      caption: 'Page will be reloaded after saving for changes to take effect',
    },
    useComplexity: 'Use @:glossary.complexity',
    useImportance: 'Use @:glossary.importance',
    extraTasksStatuses: 'Additional tasks statuses',
    changeViaSupport: 'Please contact support to change',
  },
  UserProfile: {
    caption: 'Edit profile',
    name: 'First name',
    lastName: 'Last name',
    patronymicName: 'Patronymic name',
    role: 'Position',
    phone: 'Phone',
    phoneCaption: 'Contact phone number, not used to&nbsp;log in&nbsp;to&nbsp;service.<br>To&nbsp;change login phone number, write to&nbsp;Support chat',
    email: 'E-mail',
    uploadText: {
      part1: 'Drop image here or click here to upload',
      part2: 'Supported formats:',
      part3: 'png, jpg, jpeg, gif.',
    },
    saveErr: 'Error uploading status of {name}',
    uploadAvatarErr: 'Error uploading avatar, please try again',
    deleteAvatarErr: 'Error removing avatar, please try again',
  },
  CountryCode: {
    caption: 'Select a country',
    searchInput: 'Search',
    notFound: 'Nothing found',
    close: 'Close',
  },
  allObservers: {
    caption: 'Add members',
    searchPlaceholder: 'Search',
    zeroObservers: 'No members',
    notFoundObservers: 'No results found',
    contactNotFound: 'No results found',
    badgeAdmin: 'Admin',
    badgeMeetingAdmin: 'Organizer',
    badgeOwner: 'Task creator',
    badgeAssignee: 'Assignee',
    youBadge: 'You',
    retryBtn: 'Try again',
    cancelBtn: 'Cancel',
    addBtn: 'Add',
    closeBtn: 'Close',
    addUsersBtn: 'Add members',
    user: 'members | member | members',
    errorLoadingMembers: 'Error loading member list',
    taskTitle: 'task',
    stdErr: 'An error has occurred, please try again',
    setAssignee: 'Assign',
    unsetAssignee: 'Unassign',
    removeTaskMember: {
      name: 'Delete member',
      title: 'Delete member from task',
      text: {
        part1: 'Are you sure, you want to delete contact',
        part2: 'from task',
      },
      yesText: 'Delete',
    },
    setAdmin: 'Set as administrator',
    unsetAdmin: 'Remove from administrators',
    removeMember: {
      name: 'Delete member',
      title: 'Delete contact from group',
      text: {
        part1: 'Are you sure, you want to delete contact',
        part2: 'from group',
      },
      yesText: 'Delete',
    },
    showProfile: 'Show profile',
    in: 'in',
  },
  UploadFile: {
    caption: 'File Upload',
    cancelBtn: 'Cancel',
    file: 'files | file | files',
  },
  AvatarSelector: {
    delete: 'Remove avatar',
    uploadText: {
      part1: 'Drop image or click here',
      part2: 'Supported image formats:',
      part3: 'png, jpg, jpeg, gif.',
    },
    avatar: 'Avatar',
    ofContact: 'of contact',
    ofChat: 'of chat',
    ofTeam: 'of @:featuresTerms.team',
  },
  AddContact: {
    firstName: 'First name',
    lastName: 'Last name',
    patronymicName: 'Patronymic name',
    telNeed: 'Phone Number (required)',
    tel: 'Phone Number',
    caption: 'Invite to @:featuresTerms.team',
    captionSingleGroupTeam: 'Invite to @:glossary.singleGroupTeam',
    addingTip: 'A new row will appear automatically as you filling out this form',
    cancelBtn: 'Cancel',
    addBtn: 'Add',
    stdErr: 'Error occurred, please try again later',
    toSection: 'Department',
    noSection: 'No department',
    isAdmin: 'Administrator',
    newContactsWillAutoAdd: 'New contacts will be automatically added to groups',
    addAnotherContact: 'Add another contact',
    addGroupChat: 'Add group chats',
    contactNumber: 'Contact {number}',
    inviteBtn: 'Send an invitation',
    availableSlotsTooltip: '{busy} out of {available}',
    noAvailableSlotsTooltip: 'All slots are occupied. Contact the team owner to add more',
    invalidPhoneFormat: 'Invalid phone format',
    addMoreGroups: 'Add more groups...',
    useLinkOrQR: 'Use the link or QR code for invitation',
    downloading: 'Loading...',
    copyLinkBtn: 'Copy link',
    printQRBtn: 'Print QR code',
    showQR: 'Let a colleague scan this code',
    deleteInviteLinkBtn: 'Delete invitation link',
    createInviteLink: 'Create an invitation link',
    sendInviteLink: 'And send a link or a QR code to a colleague to be invited to the team',
    createInviteLinkBtn: 'Generate link',
    otherTeamsCaption: 'Users can belong to different Personal Accounts of Team Owners. It depends on whether the invited user will take a free workplace or not',
    noAvailablePlaces: {
      caption: 'There are no vacancies left in this team',
      advise: 'If you are a team owner, you can add jobs in your Dashboard',
    },
    role: 'Role',
    byPhone: 'By phone',
    otherTeams: 'From other teams',
    QRCode: 'By QR code',
    fromNodes: {
      title: 'From nodes',
      loadUsersError: 'Error loading users.',
      contactSelectLabel: 'Select users from other nodes',
    },
    directImport: {
      title: 'From Excel',
      step1: {
        action: 'Add {number}',
        title: 'Upload',
        downloadExample: 'Download the template...',
        downloadExampleBtn: 'Download template',
        send: '...and then drag the document here',
        or: 'or',
        sendBtn: 'Download the file',
        checkData: 'Check data',
        cancelUpload: 'Cancel download',
      },
      step2: {
        title: 'Check',
        nothingFound: {
          title: 'Nothing found',
          caption: 'We couldn\'t find applicable data in the uploaded file',
        },
        selectAllCheckbox: 'Select all',
      },
      step3: {
        action: 'Stop adding',
        importStarted: 'Import has started',
        importFinished: 'Import has finished',
        captionProcessing: 'New data will start appearing in this @:featuresTerms.team as it\'s been processed on the server',
        captionDone: 'New data is already available in this @:featuresTerms.team',
        mayCloseModal: 'You may close this window now',
        hasErrors: 'Errors during import: {numErrors}',
        noErrors: 'No errors during import',
      },
      step4: {
        action: 'Ready',
      },
    },
  },
  InvitationModal: {
    title: '@:featuresTerms.team invitation',
    text: 'You\'re invited to @:featuresTerms.team',
    yesText: 'Join',
  },
  Contact: {
    firstName: 'First name',
    lastName: 'Last name',
    patronymicName: 'Patronymic name',
    role: 'Position',
    status: 'Status',
    section: 'Department',
  },
  EntityGenericList: {
    caption: 'List',
    searchPlaceholder: 'Search',
    searchEmptyMessage: 'Nothing found',
    rejectLabel: 'Close',
  },
  AvatarCropper: {
    caption: 'Choose visible area',
  },
  TextUploaderModal: {
    caption: 'unused_1',
  },
  FileUploader: {
    file_0: 'file',
    file_plural: 'files',
    dropzoneDesc: 'Drop files or click here',
    fileSizeLimit: 'Maximum upload size is {sizeLimit}Mb.',
    fileOverSize: 'File {names} is over upload limit of {limit}Mb',
    filesOverSize: 'Files {names} is over upload limit of {limit}Mb',
    fileTitle: 'File | File | Files',
    fileError: 'will not be sent because they do not meet  server security settings |' +
        'will not be sent because it does not meet server security settings |' +
        'will not be sent because they do not meet  server security settings',
  },
  GroupInstanceRow: {
    joinGroup: 'Join',
    leaveGroup: 'Leave',
    user: 'member | member | members',
  },
  IntegrationHelp: {
    help: 'Help',
  },
  MemoryUsage: {
    caption: 'Disk space usage statistics',
    on: 'On',
    sixMonth: '6 months',
    oneYear: '1 year',
    allTime: 'All time',
    details: 'Usage statistics',
    usageText: 'of {limit} are used',
  },
  GroupForm: {
    create: {
      caption: 'New Group',
      saveBtn: 'Create new group',
    },
    edit: {
      caption: 'Group Settings',
      saveBtn: 'Save',
    },
    namePlaceholder: 'Name',
    descPlaceholder: 'Topic',
    groupMembers: 'Members',
    commonPublic: 'Public Group',
    defaultForAll: 'Automatically add new @:featuresTerms.team members',
    readOnlyForMembers: 'Channel - only admins can write',
    errors: {
      create: 'Error with creating group',
      edit: 'Error with updating the @:featuresTerms.team, please try again',
      setIcon: 'Error with updating the logo, please try again',
      deleteIcon: 'Error with deleting the logo, please try again',
    },
    deleteDialog: {
      caption: 'Delete Group',
      content: 'Are you sure you want to delete group #[name]?',
      acceptLabel: 'Delete',
      acceptError: 'Group cannot be deleted at this time',
    },
  },
  GroupJoin: {
    caption: 'Public Groups',
    searchPlaceholder: 'Search group',
    notFound: 'Nothing found',
  },
  MobileApps: {
    caption: 'Download application',
    desktopApp: 'Desktop App',
    mobileApp: 'Mobile App',
    freshAndroid: 'Beta-build',
    version: 'version',
    loading: 'loading...',
  },
  MobileLogin: {
    qrLogin: 'Log in without SMS',
    qrGuide: [
      'Open tada.team on your phone',
      'Tap QR code icon in top-right corner',
      'Scan this QR code',
    ],
  },
  EntitySelector: {
    findHuman: 'Search',
    addContact: 'Add contact',
    inviteMembers: 'Invite members',
    invite: 'Invite',
    cancel: 'Cancel',
    limitReached: 'Maximum number of participants selected!',
  },
  EditSection: {
    edit: 'Edit project',
    add: 'Add project',
    name: 'Title',
    description: 'Projects are visible to all team members',
    deleteTitle: 'Delete project',
    deleteText: 'Are you sure you want to delete project <b>{name}</b>?',
  },
  FileBrowser: {
    title: 'Shared Files',
    search: 'Search',
    noResults: 'No results found',
    searchEverywhere: 'Search everywhere',
    searchIn: 'In:',
    allFileTypes: 'All file types',
    typeImage: 'Images',
    typeVideo: 'Video',
    typeFile: 'Docs',
    typeVoice: 'Voice messages',
    fromMe: 'You',
    toMe: 'Me',
    download: 'Download',
    forward: 'Forward',
    empty: 'Empty',
    contacts: 'Contacts',
    groups: 'Groups',
  },
  AutodeleteSettings: {
    title: 'Autodelete messages',
  },
  ImportTasks: {
    title: 'Import tasks',
    caption: 'Use a simple file upload to import tasks into your @:featuresTerms.team',
    step1: {
      title: 'Upload',
      xlsx: {
        title: 'Import from Excel',
        getTemplate: 'Download and fill out a template',
        uploadTemplate: 'Upload template',
      },
      trello: {
        title: 'Import from Trello',
        exportTasks: 'Export tasks from Trello',
        howToExport: '1. Show Menu<br />2. More<br />3. Print and export<br />4. Export as JSON',
        uploadTemplate: 'Upload JSON',
      },
    },
    step2: {
      title: 'Check',
      btnLabel: 'Import',
      nothingFound: {
        title: 'Nothing found',
        caption: 'We couldn\'t find applicable data in the uploaded file',
      },
    },
    step3: {
      title: 'Done',
      importStarted: 'Import has started',
      importFinished: 'Import has finished',
      captionProcessing: 'New data will start appearing in this @:featuresTerms.team as it\'s been processed on the server',
      captionDone: 'New data is already available in this @:featuresTerms.team',
      mayCloseModal: 'You may close this window now',
      hasErrors: 'Errors during import: {numErrors}',
      noErrors: 'No errors during import',
    },
  },
  ExportModal: {
    title: 'Export tasks',
    downloadAllTasks: 'Download all tasks',
    inFormat: 'in Excel',
  },
  TasksColorsRules: {
    buttonTitle: 'Formatting rules',
    header: 'Tasks formatting rules',
    priority: {
      label: 'Priority',
      tooltip: 'A task will be affected by the rule with the highest priority',
    },
    newRule: 'New rule',
    rule: {
      description: 'Description',
      project: '@:common.project',
      tags: '@:glossary.tag_plural',
      colors: 'Color',
      status: 'Status',
      importance: '@:glossary.importance',
      complexity: '@:glossary.complexity',
      urgency: '@:glossary.urgency',
    },
    notAuthorized: 'Contact your @:featuresTerms.teamAdmin to add new or change existing rules',
    noRules: 'You don\'t have any rules yet',
  },
}
